import React from "react";

import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

export default function PlantCard(props) {
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          transition: theme.transitions.create(["backgroundColor"], {
            duration: theme.transitions.duration.complex,
          }),
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Typography component="div">{props.name}</Typography>
            <Typography
              color="text.secondary"
              fontSize="16px"
              variant="body2"
              component="div"
            >
              {props.capacity_dc.toFixed(2).replace(".", ",")} kWp
            </Typography>
            <Typography
              variant="body2"
              sx={{ paddingTop: "32px" }}
              color="text.secondary"
              component="div"
            >
              Wechselrichter: {props.inverters.count}
            </Typography>
            {props.inverters.notCommunicating.length > 0 && (
              <>
                {props.inverters.active !== 0 && (
                  <Chip
                    sx={{
                      background: "rgba(46, 125, 50, 0.12)",
                      color: "rgba(46, 125, 50, 1)",
                      width: "fit-content",
                    }}
                    label={
                      props.inverters.active + " Wechselrichter sind aktiv"
                    }
                  />
                )}
                <Chip
                  sx={{
                    background: "rgba(237, 108, 2, 0.12)",
                    color: "rgba(230, 81, 0, 1)",
                    width: "fit-content",
                  }}
                  label={
                    props.inverters.count ===
                    props.inverters.notCommunicating.length
                      ? "Anlage kommuniziert nicht"
                      : props.inverters.notCommunicating.length +
                        " Wechselrichter - keine Kommunikation"
                  }
                />
              </>
            )}
            {props.inverters.notProducing.length > 0 && (
              <Chip
                sx={{
                  background: "rgba(211, 47, 47, 0.12)",
                  color: "rgba(211, 47, 47, 1)",
                  width: "fit-content",
                }}
                label={
                  props.inverters.count === props.inverters.notProducing.length
                    ? "Anlage produziert nicht"
                    : props.inverters.notProducing.length +
                      " Wechselrichter - keine Produktion"
                }
              />
            )}
            {props.inverters.notCommunicating.length === 0 &&
              props.inverters.notProducing.length === 0 && (
                <Chip
                  sx={{
                    background: "rgba(46, 125, 50, 0.12)",
                    color: "rgba(46, 125, 50, 1)",
                    width: "fit-content",
                  }}
                  label={"Alle kommunizieren  und produzieren"}
                />
              )}
          </Stack>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
