import React, { useState } from "react";
import axios from "axios";

// mui components
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

// custom components
import AgbDialog from "../components/dialog-agb";

// snippets
import EcolutionIcon from "../snippets/icon-ecolution";

function Login(props) {
  // handle user
  const [user, setUser] = useState({ pw: "", userName: "" });
  // handle dialog
  const [open, setOpen] = React.useState(false);
  const [loginWarning, setLoginWarning] = React.useState(false);
  const [permissionsWarning, setPermissionsWarning] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // handle login
  const handleLogin = () => {
    let token;
    // Make a request for a user with a given ID
    axios
      .post("/api/login", { user: user.userName, password: user.pw })
      .then(function (response) {
        // login the user and store the token
        setLoginWarning(false);
        token = response.data.token;
      })
      .then(function () {
        const config = {
          method: "get",
          url: "/api/user",
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        axios(config)
          .then(function (response) {
            const account = response.data.sqid;
            const userLastName = response.data.last_name;
            // test if account is eligible
            const eligibleAccount =
              account.includes("/pvbm/") ||
              account.includes("c3bbd662-da9f-45f8-8898-8fc88f47fef3") ||
              account.includes("87e25280-bd58-4d57-80b1-ad39f85f0cb6");

            if (eligibleAccount && userLastName.includes("(A00)")) {
              // Save token to sessionStorage
              sessionStorage.setItem("token", token);
              // set login status in state
              props.termsStatus ? props.setUserLogin(true) : handleOpen();
            } else {
              setPermissionsWarning(true);
              sessionStorage.clear();
              console.log("no permissions");
            }
          })
          .catch(function (error) {
            console.log("Account permission error:", error);
          });
      })
      .catch(function (error) {
        if (error) {
          setLoginWarning(true);
        }
        console.log("Login error:", error);
      });
  };

  return (
    <React.Fragment>
      <Container
        sx={{
          bgcolor: "#fafafa",
          minHeight: "100vh",
          minWidth: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Box width="100%" maxWidth="sm" {...props}>
          <Box
            sx={{
              pt: { xs: 12, md: 0 },
              pb: 12,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EcolutionIcon sx={{ mr: 1 }} />
            <Box>PV-Support</Box>
          </Box>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h5" component="div">
                  Log In
                </Typography>
                <TextField
                  variant="standard"
                  label="Benutzer"
                  inputProps={{ "aria-label": "Email" }}
                  onChange={(e) =>
                    setUser((prevState) => ({
                      ...prevState,
                      userName: e.target.value,
                    }))
                  }
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleLogin();
                  }}
                />
                <TextField
                  variant="standard"
                  label="Password"
                  type="password"
                  inputProps={{ "aria-label": "Password" }}
                  onChange={(e) =>
                    setUser((prevState) => ({
                      ...prevState,
                      pw: e.target.value,
                    }))
                  }
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleLogin();
                  }}
                />
                <Button size="large" variant="contained" onClick={handleLogin}>
                  Einloggen
                </Button>
                {loginWarning && (
                  <Alert severity="error">
                    Die Zugangsdaten stimmen scheinbar nicht.
                  </Alert>
                )}
                {permissionsWarning && (
                  <Alert severity="error">
                    Es liegen keine Berechtigungen zur Verwendung der App vor.
                  </Alert>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Container>

      <AgbDialog
        open={open}
        handleClose={handleClose}
        handleTerms={props.handleTerms}
      />
    </React.Fragment>
  );
}

export default Login;
